<template>
  <v-container justify="space-between">
    <v-btn :small="$vuetify.breakpoint.mdOnly" :to="{name: 'about'}" class="mx-md-1 mx-xl-2" text>
      {{ $vuetify.lang.t('$vuetify.general.navItems.about') }}
    </v-btn>
    <v-btn :small="$vuetify.breakpoint.mdOnly" :to="{name: 'contact'}" class="mx-md-1 mx-xl-2" text>
      {{ $vuetify.lang.t('$vuetify.general.navItems.contactUs') }}
    </v-btn>
    <v-btn :small="$vuetify.breakpoint.mdOnly" :to="{name: 'search'}" class="mx-md-1 mx-xl-2" text>
      {{ $vuetify.lang.t('$vuetify.general.navItems.sites') }}
    </v-btn>
    <v-btn large :to="{name: 'site'}" class="mx-md-1 mx-xl-2" color="error" outlined>
      {{ $vuetify.lang.t('$vuetify.general.becomeAVendor') }}
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'KurccHomePageNavButtons'
}
</script>
